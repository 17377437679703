define("client-base/components/cb-list/component", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/debug"], function (_exports, _component, _object, _tracking, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Show a list of items where the user can select one item.
   * 
   * This component operates in two modes:
   * 1. The selected item is provided with an external argument. You have to pass the method onSelect. 
   * You SHOULD manage the selected item in this method, otherwise it isn't possible to select an item.
   * 2. If you don't provide a selected item, we manage the state of the selected item ourselves. The 
   * method onSelect is called, if available.
   * 
   * @param {Object[]} items a list of items
   * @param {Object} [selectedItem] the selected item
   * @param {Object=} secondItem
   * @param {function(Object)=} onSelect the function to call if an item is selected
   * @param {string=} name an optional name that will be used in the assert statements
   * @param {String} sortBy
   */
  let CbList = (_class = class CbList extends _component.default {
    get selectedItem() {
      return this.args.selectedItem ?? this._selectedItem;
    }

    set selectedItem(item) {
      this._selectedItem = item;
    }

    get items() {
      let items = this.args.items,
          sortBy = this.args.sortBy;
      return sortBy ? items?.sortBy(sortBy) : items;
    }

    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "_selectedItem", _descriptor, this);

      (true && !(this.args.selectedItem === undefined || this.args.onSelect) && (0, _debug.assert)(`CbList ${this.args.name}: If you pass a selectedItem, you must also pass a method onSelect.`, this.args.selectedItem === undefined || this.args.onSelect));
      (true && !(this.args.onSelect === undefined || typeof this.args.onSelect === "function") && (0, _debug.assert)(`CbList ${this.args.name}: The argument onSelect must be a function.`, this.args.onSelect === undefined || typeof this.args.onSelect === "function"));
    }

    onSelect(item) {
      this.selectedItem = item;
      this.args.onSelect?.(item);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_selectedItem", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onSelect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSelect"), _class.prototype)), _class);
  _exports.default = CbList;
});